

.app{
    background-color: black;
    color: white;
    min-height: 100vh;

}

.app__rows.app__rows--hidden {
    display: none;
    height: 0;
    padding-top: 0px;
    padding-bottom: 0px;
}

.app__rows {
    padding-top: 65px ;
    padding-bottom: 10px;
}