.row {
    margin-left: 20px;
    padding-bottom: 33px;
    overflow: hidden;
}

.row__title--selected {
    position: relative;
    top: -9px;
}

.row__player {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row__playerInfo {
    width:100%;
    display: flex;
    justify-content: space-evenly
}

.row__posters {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
}

.row.active {
    opacity: 1;
    background-color:rgb(47, 47, 47)
}

.row {
    opacity:0.4
}

.row__posters::-webkit-scrollbar {
    display: none;
}

.row__poster {
    object-fit: contain;
    width: 100%;
    max-height: 23vh;
    margin-right: 10px;
    transition: transform 450ms;
}

 .selectedItem {
    transform: scale(1.36); 
    padding-bottom: 4px;  
 }

.row__posters img {
    box-shadow: 0 0 0.1rem rgba(0,0,0,0.75);
}

.row__carouselContainer {
    overflow: visible !important;
    padding-top: 25px;
}

@media only screen and (max-width: 1000px) and (min-width: 465px) {
    .row {
        margin-left: 4px;
        padding-bottom: 4px;
        opacity:1;
        background-color:rgb(47, 47, 47)
        }
        .row__poster {
         max-height: inherit;
        width: 10vw;
        }
    .row__title--selected {
        top: 0px;
        }
    .selectedItem {
        transform: scale(1); 
        padding-bottom: 0;  
    }
}


@media only screen and (max-width: 464px) {
    .row {
        margin-left: 4px;
        padding-bottom: 4px;
        opacity:1;
        background-color:rgb(47, 47, 47)
        }
    .row__poster {
        width: 21vw;
        }
    .row__title--selected {
        top: 0px;
        }
    .selectedItem {
        transform: scale(1); 
        padding-bottom: 0;  
    }
}