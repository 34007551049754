.banner {
    color: white;
    object-fit: contain;
    display: flex;
    min-height: 100vh;
}

.banner__contents {
    padding-top: 160px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 290px;
    color: white;
    flex: 0.2;
}

.banner__video {
    position: absolute;
}

.banner__image {
    flex:0.8;
}

.banner__imageGradient {
    z-index:2;
    min-height: 120px;
    background-image: linear-gradient(to bottom, transparent, rgba(7, 7, 7, 0.250) 0%,rgb(118, 133, 148, 0));
}

.banner__video video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.banner__videoOverlay {
  position: fixed;
  bottom: 0;
  left:0;
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}

.banner__box {
    background-color: black;
    color: #fff; 
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    width: fit-content;
    opacity: 0.8;
    padding: 5px;
}

.banner__title {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 4rem;
}

.banner__description {
    width: 35rem;
    line-height: 1.3;
    margin-top: 4rem;
    font-size: 1.3rem;
    font-weight: 400;
}

.banner__btn {
    z-index: 8;
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    font-size: 1.5rem;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 0.5rem;
}

.banner__btn:hover, .banner__btn--active {
    color: #000000;
    background-color: #e6e6e6;
    transition: all 0.2s;
}

.hidden {
    visibility: hidden;
}

.shaka-controls-container {
    display: none
}

.banner__videoOverlay hr {
  visibility: hidden;
}


@media only screen and (max-width: 1000px) and (min-width: 465px) {
.banner__contents {
    padding-top: 60px;
}

.banner__title {
     margin-bottom: 1rem;
}

.banner__btn {
    font-size: 1.3rem;
    position: relative;
}

.banner__description {
    line-height: 1.3;
    font-size: 1.2rem;
    width: 50vw;
    margin-top: 3rem;
}

}

@media only screen and (max-width: 464px) {

.banner {
    display: inherit;
    max-width: 100vw;
}

.banner__contents {
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
}

.banner__title {
    margin-bottom: 2rem;
}
.banner__btn {
    font-size: 1.1rem;
    position: relative;
}

.banner__description {
    line-height: 1.3;
    font-size: 1.1rem;
    width: inherit
}

.banner__image {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    opacity: 0.3;
    z-index: 1;
}

.banner__imageGradient {
    width: 100vw;
    height: 100vh;
}

 .banner__btn--active {
    color:inherit;
    background-color: rgba(51, 51, 51, 0.5);
}

.banner__videoOverlay--mobile  {
    display: none;
}

.shaka-controls-container {
    display: inherit;
    position: fixed;   
}

}
