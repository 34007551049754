.nav {

    position: relative;
    /* animation */

}

.nav__logo {
    position: absolute;
    top: 13px;
    right: 30px;
    height: 45px;
    object-fit: contain;
}

.nav__avatar {
    position: absolute;
    top: 13px;
    left: 30px;
    height: 48px;
    object-fit: contain;
}

@media only screen and (max-width: 1000px) and (min-width: 465px) {
.nav__logo {
    height: 26px;
}

.nav__avatar {
    height: 30px;
    left: 20px;
}
}

@media only screen and (max-width: 464px) {
.nav__logo {
    height: 26px;
}

.nav__avatar {
    height: 30px;
    left: 20px;
}

}
